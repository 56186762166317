import { createContext, useContext, useState, useEffect, useRef } from "react";
import { useRouter } from "next/router";
import {
  ONBOARDING_VIEW,
  SCHOOL_CARD_EDIT,
  FREEMIUM_PRICE_ID,
  WALKTHROUGH_VIDEO,
  EFC_VIDEO_1,
  GOOGLE,
  POST_LOGOUT_URL,
} from "@utils/constants";
import { getUserSchools } from "@api/schools";
import { getScenarioOffers } from "@api/offers";
import { getUser, updateUser } from "@api/user";
import { refreshToken, socialLogin } from "@api/auth";
import { calculateNetCostForAddedSchools } from "@api/calculateScenario";
import { parseJwt, getTokenPercentExpiry } from "@utils/parseJwt";
import {
  getAccessTokenFromDocument,
  getAdminAccessTokenFromUrl,
  getAdminAccessTokenFromCookies,
  getSSOAccessTokenFromUrl,
  removeAccessToken,
  removeAdminAccessToken,
  setAccessToken,
  setAdminAccessToken,
} from "@utils/getAccessToken";
import { loginAsUser, logoutFromUser, ssoLoginAsUser } from "@api/admin";
import _ from "lodash";
import { getEfcEval } from "@utils/efcEval";
import {
  getReferralCodeFromSession,
  removeReferralCode,
} from "@utils/getReferralCode";
import { useAuth0 } from "@auth0/auth0-react";
import { settings } from "@whitelabel/whitelabel.preval.js";
import { openExternalLink } from "@utils/externalLink";
import { convertDaysToMs } from "@utils/dateFormatter";
import { getSubscriptionRemainingTime } from "@utils/getSubscriptionRemainingTime";
import * as Sentry from "@sentry/nextjs";
import { gtmPushAccountCreated, gtmPushLogin } from "@utils/gtm";
import {
  hasExSpouse,
  hasSpouse,
  isFilingJointly,
  isFilingSeparately,
} from "@utils/maritalStatusCheck";

const AppContext = createContext();

export function AppWrapper({ children }) {
  const { isAuthenticated, logout, getAccessTokenSilently } = useAuth0();

  const router = useRouter();

  //******************************************//
  //*********** ----- STATE ----- ************//
  //******************************************//

  //Auth
  const [siteLoading, setSiteLoading] = useState(true); //Used to prevent premature redirects
  const [loggedIn, setLoggedIn] = useState(false);
  const [upgraded, setUpgraded] = useState(false); //TODO: add logic according to backend upgraded status
  const [isIecAndImpersonating, setIsIecAndImpersonating] = useState(false);
  const [heapInitialized, setHeapInitialized] = useState(false);

  //Layout
  const [siteInteract, setSiteInteract] = useState(false);
  const [displaySidebar, setDisplaySidebar] = useState(false);
  const [displayModal, setDisplayModal] = useState(false);
  const [modalContent, setModalContent] = useState([]);
  const [modalView, setModalView] = useState(ONBOARDING_VIEW);
  const [displayFormModal, setDisplayFormModal] = useState(false);
  const [formModalContent, setFormModalContent] = useState([]);
  const [formModalView, setFormModalView] = useState(SCHOOL_CARD_EDIT);
  const [scrollToCard, setScrollToCard] = useState(0); //set a scrollLeft value to start at
  const [displayErrorModal, setDisplayErrorModal] = useState(false);
  const [displayVideoModal, setDisplayVideoModal] = useState(false);
  const [videoModalContent, setVideoModalContent] = useState(WALKTHROUGH_VIDEO);
  const [pdfViewerContent, setPdfViewerContent] = useState(null);
  //efcVideo is an object with related text and video src
  const [efcVideo, setEfcVideo] = useState({
    text1:
      "Student *WILL* likely be eligible for need-based grants at most public and private colleges.",
    text2:
      "Student will also be eligible for merit-based scholarships at some colleges!",
    video: EFC_VIDEO_1,
  });
  const [isOnboarding, setIsOnboarding] = useState(false);
  const [howToPaySchool, setHowToPaySchool] = useState({});
  const [freePlanSelected, setFreePlanSelected] = useState("");
  const [initialExpanded, setInitialExpanded] = useState(false);
  const [needMoreInfo, setNeedMoreInfo] = useState(false);
  const [eligibleForRenewal, setEligibleForRenewal] = useState(false);

  //Data
  //user vs. scenario. What's the difference?
  //Any student may have only 1 scenario, but 1 user, who may be a parent with multiple children,
  //can have multiple scenarios. Multiple scenarios is not yet supported, but this is the distinction.
  //scenario holds the bulk of the data, whereas user only holds a few things, such as email, and other contact info.
  //case_id is relevant for scenario, not user, but functionally case_id can be thought of as a user id
  const [user, setUser] = useState({});
  //Initiate scenario with free subscription plan
  const [scenario, setScenario] = useState({
    subscription_info: {
      is_paid: false,
      price_id: FREEMIUM_PRICE_ID,
    },
  });
  const [userSchools, setUserSchools] = useState([]);
  const [advancedSearchList, setAdvancedSearchList] = useState([]);
  const [advancedSearchListChecked, setAdvancedSearchListChecked] = useState(
    []
  );
  const [CAPFilterDefaults, setCAPFilterDefaults] = useState({});
  const [advancedFilter, setAdvancedFilter] = useState("");
  const [refresh, setRefresh] = useState("NoWay");
  const [filterWidth, setFilterWidth] = useState("100%");
  const [errorResponse, setErrorResponse] = useState({});
  const [userOffers, setUserOffers] = useState([]);

  const [consultationAppointment, setConsultationAppointment] = useState({});
  const [hasPurchasedConsultation, setHasPurchasedConsultation] =
    useState(false);

  const [barLoading, setBarLoading] = useState(false);
  const [percentLoaded, setPercentLoaded] = useState(0);
  const [loadedEfcs, setLoadedEfcs] = useState(false);
  const [loadedSchools, setLoadedSchools] = useState(false);
  const [loadedOffers, setLoadedOffers] = useState(false);

  const [polling, setPolling] = useState(false);
  //state to track if all schools need recalculating
  const [hasRecalculatedAllSchools, setHasRecalculatedAllSchools] =
    useState(false);

  const [showBanner, setShowBanner] = useState(true);

  //Ref for storing the polling start time
  const pollingCalculationsRef = useRef();
  //******************************************//
  //******** ----- RESET STATE ----- *********//
  //******************************************//

  /**
   * Reset State
   * Method for returning all state values to default
   * @params - none
   */
  const resetState = () => {
    //Auth
    setLoggedIn(false);
    setUpgraded(false);
    setIsIecAndImpersonating(false);
    //Layout
    setDisplaySidebar(false);
    setDisplayModal(false);
    setModalContent([]);
    setModalView(ONBOARDING_VIEW);
    setDisplayFormModal(false);
    setFormModalContent([]);
    setFormModalView(SCHOOL_CARD_EDIT);
    setPdfViewerContent(null);
    setScrollToCard(0); //set a scrollLeft value to start at
    setInitialExpanded(false);
    setDisplayVideoModal(false);
    setVideoModalContent(WALKTHROUGH_VIDEO);
    setEfcVideo({
      text1:
        "Student *WILL* likely be eligible for need-based grants at most public and private colleges.",
      text2:
        "Student will also be eligible for merit-based scholarships at some colleges!",
      video: EFC_VIDEO_1,
    });
    setIsOnboarding(false);
    setHowToPaySchool({});
    setShowBanner(false);

    //Data
    setUser({});
    setScenario({
      subscription_info: {
        is_paid: false,
        price_id: FREEMIUM_PRICE_ID,
      },
    });
    setUserSchools([]);
    setAdvancedSearchList([]);
    setAdvancedSearchListChecked([]);
    setCAPFilterDefaults({});
    setAdvancedFilter("");
    setFilterWidth("100%");
    setUserOffers([]);
    setConsultationAppointment({});
    setHasPurchasedConsultation(false);
    setFreePlanSelected(false);
    setBarLoading(false);
    setPercentLoaded(0);
    setLoadedEfcs(false);
    setLoadedSchools(false);
    setLoadedOffers(false);
    setPolling(false);
    setHasRecalculatedAllSchools(false);
    setHeapInitialized(false);
    Sentry.configureScope((scope) => {
      scope.setUser(null);
      // scope.clear();
    });
  };

  //******************************************//
  //********* ----- FUNCTIONS ----- **********//
  //******************************************//

  /**
   * Login via external site
   * @param {*} data - response data
   */
  const handleExternalLogin = (data) => {
    if (data?.jwt) {
      localStorage.setItem("refresh_token", data.jwt?.refresh_token);
      setAccessToken(data.jwt?.access_token);
      setLoggedIn(true);
      //remove referral code if present
      if (getReferralCodeFromSession()) {
        removeReferralCode();
      }
      //set scenario for instant visual on name and efcs
      setScenario({
        ...scenario,
        case_id: data.case_id,
        onboarding: data.onboarding,
        efcs: data.efcs,
        preapprovals: data.preapprovals,
      });
      checkForNeededDashboardInfo(data.onboarding);
      gtmPushLogin(process.env.NEXT_PUBLIC_SOURCE);
      router.push("/");
    }
  };

  /**
   * Login via Google
   * @param {*} response
   */
  const responseGoogle = async (response) => {
    const accessToken = _.get(response.tokenObj, "access_token");
    if (accessToken) {
      //check for referral code
      const referralCode = getReferralCodeFromSession();
      try {
        const res = await socialLogin(accessToken, referralCode);
        if (res.data?.jwt) {
          localStorage.setItem("refresh_token", res.data.jwt?.refresh_token);
          setAccessToken(res.data.jwt?.access_token);
          setLoggedIn(true);
          //remove referral code if present
          if (getReferralCodeFromSession()) {
            removeReferralCode();
          }
          //set scenario for instant visual on name and efcs
          setScenario({
            ...scenario,
            case_id: res.data.case_id,
            onboarding: res.data.onboarding,
            efcs: res.data.efcs,
            preapprovals: res.data.preapprovals,
          });
          checkForNeededDashboardInfo(res.data.onboarding);
          //No efcs object present for registration, go to onboarding. TODO: Provide redirect route in response from API so weird logic like this isn't necessary.
          if (!res.data.efcs) {
            //push event to Google Tag Manager
            gtmPushAccountCreated(GOOGLE);
            router.push("/onboarding");
          } else {
            gtmPushLogin(GOOGLE);
            router.push("/");
          }
        }
      } catch (error) {
        handleApiError(error);
      }
    }
  };

  /**
   * Let admin impersonate user
   */
  const impersonateUser = async () => {
    console.log("impersonating user");
    try {
      const res = await loginAsUser();
      console.log(impersonateUser, res);
      if (res.data?.jwt) {
        //set tokens
        setAccessToken(res.data.jwt?.access_token);
        localStorage.setItem("refresh_token", res.data.jwt?.refresh_token);
        setLoggedIn(true);
        //remove token from url
        router.replace("/");
      }
    } catch (error) {
      handleApiError(error);
    }
  };

  /**
   * Login via SSO from "auth" site
   * @param {string} JWT access token
   */
  const ssoUser = async (accessToken) => {
    try {
      const res = await ssoLoginAsUser(accessToken);
      if (res.data?.jwt) {
        // Set tokens and indicate we're logged in
        setAccessToken(res.data.jwt?.access_token);
        localStorage.setItem("refresh_token", res.data.jwt?.refresh_token);
        setLoggedIn(true);

        // Remove token from URL to make it look nice
        router.replace("/");
      }
    } catch (error) {
      // SSO login failed
      // This will display a popup message and immediately(!) redirect to the "www" site
      handleApiError(error);
    }
  };

  /**
   * Refresh the token that has less than half the time remaining
   * @param {boolean} isImpersonating - true if user is currently being impersonated by an Admin
   */
  const refreshOldToken = async (isImpersonating = false) => {
    try {
      const res = await refreshToken(isImpersonating);
      if (res.data) {
        setAccessToken(res.data.jwt?.access_token);
        localStorage.setItem("refresh_token", res.data.jwt?.refresh_token);
      }
    } catch (error) {
      handleApiError(error);
    }
  };

  /**
   * @returns {boolean}
   */
  const checkIsIec = () => {
    return _.get(
      parseJwt(getAdminAccessTokenFromCookies(document.cookie)),
      "iec"
    );
  };

  const getAuth0AccessToken = async () => {
    try {
      const accessToken = await getAccessTokenSilently();
      if (accessToken) {
        setAccessToken(accessToken); //NOTE: May be better to retrieve access token for every call to take advantage of auto refreshing of token, or call in handleUserToken
        setLoggedIn(true);
        return accessToken;
      }
    } catch (error) {
      console.warn(error);
    }
  };

  /**
   * Handle User Token
   * Check if access_token stored is valid, and refresh token if the expiry time is less than 60%
   * @params - none, but does require a valid jwt token present in localStorage
   * @returns {boolean} - true if token valid, false if expired or not present
   */
  const handleUserToken = () => {
    if (
      process.env.NEXT_PUBLIC_WHITELABEL === "true" &&
      process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID
    ) {
      if (isAuthenticated) {
        getAuth0AccessToken();
        return true;
      }
      return false;
    } else {
      const access_token = getAccessTokenFromDocument();
      const isValidAdminEntry =
        router.pathname !== "/schedule_meeting/[user_id]";
      const urlAdminAccessToken = isValidAdminEntry
        ? getAdminAccessTokenFromUrl()
        : null;
      //if admin token present, current token is not valid so do not proceed as if token can be used
      if (urlAdminAccessToken) {
        return false;
      }
      //get the percent time remaining on the access token
      const percentRemaining = getTokenPercentExpiry(access_token);
      if (percentRemaining) {
        //if token expired, logout
        if (percentRemaining < 0) {
          myCapLogout();
          return false;
        }
        //Check if user is currently being impersonated by an Admin
        const isImpersonating = handleAdminToken(
          getAdminAccessTokenFromCookies(document.cookie)
        );
        if (isImpersonating) {
          setIsIecAndImpersonating(checkIsIec());
        }
        //If percent time remaining on token is less than 60%, refresh the token.
        if (percentRemaining < 60) {
          refreshOldToken(isImpersonating);
        }
        //token is a valid format and has time remaining, return true
        return true;
      } else {
        //Token is an invalid format, logout and reroute to main page.
        if (loggedIn) {
          myCapLogout();
        }
        return false;
      }
    }
  };

  /**
   * Handle Admin Token
   * Check if admin token's user id matches the logged in user
   * @param {string} adminToken - must be valid jwt token
   * @returns {boolean} - true if admin is impersonating, false if not impersonating this user or if admin token not present
   */
  const handleAdminToken = (adminToken) => {
    //Check if user is currently being impersonated by an Admin
    if (adminToken) {
      const adminTokenObject = parseJwt(adminToken);
      const userTokenObject = parseJwt(getAccessTokenFromDocument());
      //admin token has uid for the user and adminid for the admin. Compare the uid to the access token to check if impersonating this user.
      const isImpersonating =
        _.get(adminTokenObject, "uid") === _.get(userTokenObject, "uid");
      return isImpersonating;
    } else {
      return false;
    }
  };

  /**
   * Initiate polling to get results from job. Initial call after optimistic 1 second
   */
  const initiatePollingCalculations = () => {
    //Timing must be exact and usable right away, so don't use state for polling start
    pollingCalculationsRef.current = Date.now();
    //initial poll after 5 seconds
    setTimeout(pollForUpdatedAt, 3000);
  };

  /**
   * Reset has calculated. call for setTimeout and clearTimeout
   */
  const resetHasCalculated = () => {
    setHasRecalculatedAllSchools(false);
  };

  /**
   * Check updated_at in db until value does not match scenario_updated_at value in localStorage.
   * @param {integer} previousDelay - passing in the previous delay allows for ramping down how often the polling runs
   */
  const pollForUpdatedAt = async (previousDelay) => {
    try {
      const res = await getUser();
      if (res.data?.case_id) {
        const currentTimestamp = res.data.scenario.updated_at;
        const lastTimestamp = localStorage.getItem("scenario_updated_at");
        if (lastTimestamp) {
          const timeSincePollingStarted =
            Date.now() - pollingCalculationsRef.current;
          //If timestamps don't match, it means the scenario was updated by B2B. Stop trying after 6 minutes.
          if (
            currentTimestamp !== lastTimestamp ||
            timeSincePollingStarted > 360000
          ) {
            //Job has finished. Reset ref and localStorage
            setPolling(false);
            //Don't recalculate again unnecessarily
            setHasRecalculatedAllSchools(true);
            //reset state to allow calculate all if session lasts more than 24 hours after calculating without refreshing
            const timeUntilReset = convertDaysToMs(1);
            setTimeout(resetHasCalculated, timeUntilReset);
            pollingCalculationsRef.current = null;
            localStorage.removeItem("scenario_updated_at");
          } else {
            //poll for updated_at value again after delay. This logic means the poll will run at 3, 6, 12, 21, 33, 48 seconds...
            const currentDelay = previousDelay ? previousDelay + 3000 : 3000;
            setTimeout(() => {
              pollForUpdatedAt(currentDelay);
            }, currentDelay);
          }
        }
      }
    } catch (error) {
      handleApiError(error);
    }
  };

  /**
   * Recalculate added schools and populate updated efc and school data
   * @param {object} res - response from api call
   * @param {object} scenarioObject - uses scenario state by default, but can be passed in to ensure state isn't set in the wrong order.
   */
  const setScenarioSchoolsData = (res, scenarioObject = scenario) => {
    const result = _.get(res, "data.result");
    if ("schools" in result) {
      //set schools
      const schoolData = _.get(res, "data.result.schools");
      const schoolArray = Object.values(schoolData).filter(
        (school) => school !== null
      );
      setUserSchools(schoolArray);
      setLoadedSchools(true);
      //reset recalculated all state
      setHasRecalculatedAllSchools(false);
      clearTimeout(resetHasCalculated);
    }
    if ("preapprovals" in result && "efcs" in result) {
      //set efcs and preapprovals
      const newPreapprovals = {
        ...scenarioObject.preapprovals,
        ..._.get(res, "data.result.preapprovals"),
      };
      setScenario({
        ...scenarioObject,
        efcs: _.get(res, "data.result.efcs"),
        preapprovals: newPreapprovals,
      });
      setLoadedEfcs(true);
    }
  };

  /**
   * Recalculate added schools and populate updated efc and school data without recalculating scholarships
   * @param {Integer} case_id
   * @param {object} scenarioObject - uses scenario state by default, but can be passed in to ensure state isn't set in the wrong order.
   */
  const populateRecalculatedAddedSchools = async (
    case_id,
    scenarioObject = scenario
  ) => {
    try {
      //use calculate to get school details to ensure data is up to date
      const res = await calculateNetCostForAddedSchools(case_id);
      setScenarioSchoolsData(res, scenarioObject);
    } catch (error) {
      handleApiError(error);
    }
  };

  /**
   * Populate User
   * get the user data and set it to context.
   * When to authenticate user and repopulate the scenario and user state:
   * Logging In
   * Updating onboarding questions (profile > personal info)
   * Updating email/ password (profile > account info)
   * Updating college Pre-Approvals (profile > college pre-approvals)
   * @params - none, but does require a valid jwt token present in localStorage
   */
  const populateUser = async () => {
    try {
      const res = await getUser();
      if (res.data?.case_id) {
        //loggedIn state may not be set if revisiting a tab.
        if (!loggedIn) {
          setLoggedIn(true);
        }
        //authenticated, now populate user state
        setUser(res.data.user);
        const newScenario = {
          case_id: res.data.case_id,
          ...res.data.scenario,
          subscription_info: res.data.plan_info,
        };
        setScenario(newScenario);
        checkForNeededDashboardInfo(_.get(res.data.scenario, "onboarding"));
        if (res.data.plan_info.price_id !== FREEMIUM_PRICE_ID) {
          //TODO: with subscription_info, upgraded state may be deprecated. Just use the scenario's price_id for logic checks.
          setUpgraded(true);
        }
        //When scenario is retrieved, recalculate.
        populateRecalculatedAddedSchools(res.data.case_id, newScenario);
        // Set user information, as well as tags and further extras
        Sentry.configureScope((scope) => {
          scope.setUser({ email: res.data.user.email });
        });
      }
    } catch (error) {
      /**
       * Passing  second argument to logout user
       * if not able to get user by token.
       */
      handleApiError(error, true);
    }
  };

  const myCapLogout = async () => {
    if (
      process.env.NEXT_PUBLIC_WHITELABEL === "true" &&
      process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID
    ) {
      //logout from Auth0
      removeAccessToken();
      logout({
        returnTo: `${process.env.NEXT_PUBLIC_URL_PROTOCOL}//${process.env.NEXT_PUBLIC_VERCEL_URL}`,
      });
      //TODO: change logout redirect url to wordpress site?
    } else {
      //if user is an admin, redirect to admin panel
      const isImpersonating = handleAdminToken(
        getAdminAccessTokenFromCookies(document.cookie)
      );
      if (isImpersonating) {
        try {
          const res = await logoutFromUser();
          if (res?.data) {
            //expire cookies
            localStorage.removeItem("refresh_token");
            removeAccessToken();
            removeAdminAccessToken();
            sessionStorage.clear();
            const redirectToAdminURL = res.data;
            router.push(redirectToAdminURL);
          }
        } catch (error) {
          //expire cookies
          localStorage.removeItem("refresh_token");
          removeAccessToken();
          removeAdminAccessToken();
          sessionStorage.clear();
          /**
           * Passing in second argument to logout user
           * if not able to get user by token. Should show error message and logout without redirecting.
           */
          handleApiError(error, true);
        }
      } else {
        //reset token
        localStorage.removeItem("refresh_token");
        removeAccessToken();
        sessionStorage.clear();
        if (process.env.NEXT_PUBLIC_WHITELABEL === "true") {
          router.push(settings?.redirectUrl);
        } else {
          // If we get here, we must be logging off a MYCAP account... go back to "www" site
          router.push(POST_LOGOUT_URL);
        }
        //log out
        setLoggedIn(false);
        //Reset all states to default
        //NOTE: Keep an eye on reset state, may interfere with form states
        resetState();
      }
    }
  };

  /**
   * Centralized error handling function. Also triggers error modal. Not used for certain types of errors that will have feedback integrated into form.
   * Always used for handling the error in a try/catch block.
   * @param {object} error - Error response object that was caught
   * @param {logoutUser} boolean - Whether to logout user or not
   */
  const handleApiError = (error, logoutUser = false) => {
    console.log(error);
    //401: The server doesn't recognize your credentials as valid
    //403: The server recognizes your credentials as valid, but you don't have permission to access the requested content
    //403 is for authorization. For example, if a Freemium user tries to
    //navigate to a premium feature such as /advanced_search,
    //They should be redirected to "/" and shown an error modal with the error's message
    //For unauthenticated (401), server does not recognize you, so log out and reset token.
    //Error modal is primarily used for errors other than those expected 401s from form inputs.

    //If impersonated email does not match user, do not grant admin access. Should allow tabs with different sessions to not interfere with eachother.
    const isImpersonating = handleAdminToken(
      getAdminAccessTokenFromCookies(document.cookie)
    );
    const userAccessToken = getAccessTokenFromDocument();
    //get the percent time remaining on the access token
    const percentRemaining = getTokenPercentExpiry(userAccessToken);
    //TODO: Improve logic so token can be refreshed if refresh token has time remaining but access token is expired.
    if (percentRemaining < 0) {
      //if user is being impersonated by admin, show session expired message, else just log out
      if (isImpersonating) {
        setErrorResponse({
          status: "401: Token has been expired",
          result: { message: "Your admin session expired" },
        });
      }
      myCapLogout();
      return false;
    }
    //token not expired, handle response
    const response = error?.response ? error.response : error;
    //create object for error modal to handle
    const errorModalObject = {
      status: response?.status,
      statusText: response?.statusText,
      result: _.get(response, "data.result"),
    };
    if (response?.status) {
      //TODO: reorder the error code and error message in modal
      if (response?.status === 401 || logoutUser) {
        setErrorResponse(errorModalObject);
        setDisplayErrorModal(true);
        myCapLogout();
        return false;
      }
      if (response?.status >= 500) {
        //server error
        //TODO: should this be displayed or only sent to a console.warning
        setErrorResponse({
          status: 500,
          statusText: "Internal Server Error.",
          message: (
            <div>
              <span>
                Oops! Something went wrong. <br />
                Please try again or{" "}
                <a href="mailto:info@collegeaidpro.com">contact us</a> if the
                problem persists.
              </span>
            </div>
          ),
        });
      } else {
        if (response?.data?.result) {
          //This will usually be a 422
          //.email, .password
          //errors onject can include any number of different error messages with different keys. TODO: standardize another return value for the UI, "message".
          //Current way is not conducive to being read or understood by the user
          //For example, it might come back as
          // "errors": {
          //   "onboarding.parent_guardian.marital_status": [
          //       "The onboarding.parent guardian.marital status must be a valid marital status."
          //   ]
          // }
          setErrorResponse(errorModalObject);
        } else {
          //Other 4XX errors, should include .result and .status
          setErrorResponse(response?.data);
        }
      }
    } else {
      if (error.message) {
        setErrorResponse(error);
      } else {
        setErrorResponse({ status: "", message: "Network Error" });
      }
    }
    //Display error modal
    setDisplayErrorModal(true);
  };

  /**
   * Get schools for user and set to state in context
   * so we have an updated list sitewide without having to make more calls to the backend.
   * @param {number} case_id - Id of a user's scenario
   * Instead of returning a value, this function sets the state of the list of user's added schools in context
   */
  const populateUserSchools = async (case_id) => {
    try {
      const res = await getUserSchools(case_id);
      if (res?.data?.results) {
        setUserSchools(res?.data?.results);
        setLoadedSchools(true);
      }
    } catch (error) {
      handleApiError(error);
    }
  };

  /**
   * Get offers for scenario and set to state in context
   * so we have an updated list sitewide without having to make more calls to the backend.
   * @param {number} case_id - Id of a user's scenario
   * Instead of returning a value, this function sets the state of the list of user's added schools in context
   */
  const populateOffers = async (case_id) => {
    try {
      const res = await getScenarioOffers(case_id);
      if (res.data) {
        setUserOffers(
          _.orderBy(res.data.result, (offer) => offer.school.name, ["asc"])
        );
        setLoadedOffers(true);
      }
    } catch (error) {
      handleApiError(error);
    }
  };

  /**
   * Update the state of offers and tuition budgets
   * @param {object} updatedOffer
   * @param {object} updatedTuitionBudget
   */
  const updateOffersState = (updatedOffer) => {
    const newUserOffers = userOffers.map((offer) => {
      if (offer?.id === updatedOffer?.id) {
        return updatedOffer;
      } else {
        return offer;
      }
    });
    setUserOffers(newUserOffers);
  };

  const updateWalkthroughVideoPlayed = async () => {
    if (user?.email) {
      try {
        const res = await updateUser(user?.email, {
          walkthrough_video_played: true,
        });
        if (res.data?.result) {
          setUser(res.data.result);
        }
      } catch (error) {
        handleApiError(error);
      }
    }
  };

  const openWalkthroughVideo = () => {
    if (process.env.NEXT_PUBLIC_WHITELABEL === "true") {
      if (settings?.walkthroughVideo) {
        setVideoModalContent(settings?.walkthroughVideo || WALKTHROUGH_VIDEO);
        setDisplayVideoModal(true);
      }
      //update user if not walkthrough_video_played
      if (!_.get(user, "walkthrough_video_played")) {
        updateWalkthroughVideoPlayed();
      }
    } else {
      setVideoModalContent(WALKTHROUGH_VIDEO);
      setDisplayVideoModal(true);
    }
  };

  const openEfcVideo = () => {
    setVideoModalContent(efcVideo?.video);
    setDisplayVideoModal(true);
  };

  const routeToExpertMeeting = () => {
    if (
      process.env.NEXT_PUBLIC_WHITELABEL === "true" &&
      _.get(settings, "bookExpertMeetingLink")
    ) {
      openExternalLink(_.get(settings, "bookExpertMeetingLink"));
    } else {
      router.push(`/book_consultation/${encodeURIComponent(user?.email)}`);
    }
  };

  //******************************************//
  //********* ----- USEEFFECTS ----- *********//
  //******************************************//

  useEffect(() => {
    async function handleAuth() {
      //TODO: rename admin redirect token something more specific than "token" so this check isn't necessary
      const isValidAdminEntry =
        router.pathname !== "/schedule_meeting/[user_id]";
      const urlAdminAccessToken = isValidAdminEntry
        ? getAdminAccessTokenFromUrl()
        : null;
      //Always start new session when admin token comes from url
      if (urlAdminAccessToken) {
        //remove any existing tokens
        localStorage.removeItem("refresh_token");
        removeAccessToken();
        //set admin token in cookies
        setAdminAccessToken(urlAdminAccessToken);
        setIsIecAndImpersonating(checkIsIec());
        impersonateUser();
      }
    }

    // ?sso=xxxx will get passed in from the "auth" site
    // When testing, make sure bad sso values get handled appropriately (e.g. http://127.0.0.1:3000/?sso=xxxx)
    const ssoAccessToken = getSSOAccessTokenFromUrl();
    if (ssoAccessToken) {
      // Remove any existing tokens
      localStorage.removeItem("refresh_token");
      removeAccessToken();

      // Process the SSO login now
      ssoUser(ssoAccessToken);
    }

    handleAuth();
  }, []);

  useEffect(() => {
    if (loggedIn) {
      //gets user info every time a user logs in.
      //loggedIn cannot be set to true unless access token has passed validation
      //Includes initial navigation after logging in, populates efcs where register User does not
      populateUser();
    }
  }, [loggedIn]);

  useEffect(() => {
    //This useEffect should trigger any time scenario id is updated.
    //This will not be triggered by things related to the scenario but are not a direct update to the scenario state
    //such as userSchools, scholarships, etc.
    //only populate once valid scenario is present
    if (scenario.case_id) {
      setBarLoading(true);
      populateUserSchools(scenario.case_id);
      populateOffers(scenario.case_id);
    }
  }, [scenario.case_id]);

  useEffect(() => {
    //Scenario efc video number has been updated, therefore rerun efc video logic.
    if (scenario?.efcs) {
      const newEfcVideo = getEfcEval(
        scenario.onboarding?.student?.student_name,
        //In the case that efcs don't match any video, set to last video (video 9)
        _.isInteger(scenario.efcs.efc_video_number)
          ? scenario.efcs.efc_video_number
          : 9
      );
      setEfcVideo(newEfcVideo);
    }
  }, [scenario.efcs?.efc_video_number]);

  useEffect(() => {
    if (barLoading) {
      //set bar length based on which data has loaded.
      //Use 1 freebie as true so that the bar visually indicates that it has started loading. The other 80% will be progressed through as data loads.
      const loadedPercentage =
        ((true + !!loadedEfcs + !!loadedSchools + !!loadedOffers) / 4) * 100;
      setPercentLoaded(loadedPercentage);
    } else if (!barLoading && percentLoaded === 100) {
      //reset loaded state after delay. Bar takes 500 ms to hide, then reset state
      setTimeout(() => {
        setPercentLoaded(0);
        setLoadedEfcs(false);
        setLoadedSchools(false);
        setLoadedOffers(false);
      }, 500);
    }
  }, [barLoading, percentLoaded, loadedEfcs, loadedSchools, loadedOffers]);

  /**
   * Initialize heap user settings per session
   */
  useEffect(() => {
    if (typeof window !== "undefined") {
      if (window.heap && user?.email && scenario?.case_id) {
        if (!heapInitialized) {
          console.warn("Heap initialized");
          window.heap.identify(`scenario-${scenario.case_id}`);
          window.heap.addUserProperties({ email: user.email });
          setHeapInitialized(true);
        }
      }
    }
  }, [user, scenario, heapInitialized]);

  /**
   * Toggle NoInfoCTA. Calling this in a useEffect based on scenario will unnecessarily run this function sometimes.
   * @param {*} profileData
   */
  // TODO centralize this logic with calcEnhancedProfile perhaps, should discuss requirements with the client
  const checkForNeededDashboardInfo = (profileData) => {
    if (profileData) {
      const { starting_college_year, scores, zip } = profileData?.student || {};
      const {
        parent_earnings,
        parent_earnings_2,
        parent_earnings_3,
        agi,
        agi_2,
        agi_3,
        agi_user_provided,
        agi_user_provided_2,
        agi_user_provided_3,
        investments,
        investments_2,
        investments_3,
        marital_status,
        filing_status,
      } = profileData?.parent_guardian || {};

      const hasSpouseResult = hasSpouse(marital_status);
      const hasExSpouseResult = hasExSpouse(marital_status);
      const isFilingJointlyResult = isFilingJointly(
        filing_status,
        marital_status
      );
      const allow_agi_1 = !(hasSpouseResult && isFilingJointlyResult);

      const gradYear = starting_college_year !== null;
      const zipCode = zip !== null;
      const unweightedGpa = scores?.gpa !== null;
      const income =
        parent_earnings !== null ||
        (hasSpouseResult && parent_earnings_2 !== null) ||
        (hasExSpouseResult && parent_earnings_3 !== null) ||
        (allow_agi_1 && agi_user_provided === true && agi !== null) ||
        (hasSpouseResult && agi_user_provided_2 === true && agi_2 !== null) ||
        (hasExSpouseResult && agi_user_provided_3 === true && agi_3 !== null);

      const assets =
        investments !== null ||
        investments_2 !== null ||
        investments_3 !== null;
      if (!(gradYear && unweightedGpa && income && assets && zipCode)) {
        setNeedMoreInfo(true);
      } else {
        setNeedMoreInfo(false);
      }
    }
  };

  useEffect(() => {
    if (process.env.NEXT_PUBLIC_WHITELABEL !== "true") {
      const subscription_info = _.get(scenario, "subscription_info");
      if (subscription_info) {
        if (!subscription_info.has_renewal_plan) {
          const planRemainingDays = getSubscriptionRemainingTime(
            _.get(subscription_info, "created_at")
          );
          setEligibleForRenewal(planRemainingDays <= 28);
        }
      }
    }
  }, [scenario]);

  //******************************************//
  //********** --- SHARED STATE --- **********//
  //******************************************//

  const sharedState = {
    //reset
    resetState,
    //functions
    handleExternalLogin,
    responseGoogle,
    handleAdminToken,
    handleUserToken,
    populateUser,
    myCapLogout,
    handleApiError,
    populateUserSchools,
    populateOffers,
    updateOffersState,
    openWalkthroughVideo,
    openEfcVideo,
    initiatePollingCalculations,
    populateRecalculatedAddedSchools,
    checkForNeededDashboardInfo,
    routeToExpertMeeting,
    //whitelabel
    getAuth0AccessToken,
    //auth
    siteLoading,
    setSiteLoading,
    loggedIn,
    setLoggedIn,
    upgraded,
    setUpgraded,
    isIecAndImpersonating,
    setIsIecAndImpersonating,
    //layout
    siteInteract,
    setSiteInteract,
    displaySidebar,
    setDisplaySidebar,
    displayModal,
    setDisplayModal,
    modalContent,
    setModalContent,
    modalView,
    setModalView,
    displayFormModal,
    setDisplayFormModal,
    formModalView,
    setFormModalView,
    formModalContent,
    setFormModalContent,
    scrollToCard,
    setScrollToCard,
    displayErrorModal,
    setDisplayErrorModal,
    displayVideoModal,
    setDisplayVideoModal,
    videoModalContent,
    setVideoModalContent,
    pdfViewerContent,
    setPdfViewerContent,
    efcVideo,
    setEfcVideo,
    isOnboarding,
    setIsOnboarding,
    howToPaySchool,
    setHowToPaySchool,
    freePlanSelected,
    setFreePlanSelected,
    initialExpanded,
    setInitialExpanded,
    needMoreInfo,
    setNeedMoreInfo,
    eligibleForRenewal,
    setEligibleForRenewal,
    //data
    user,
    setUser,
    scenario,
    setScenario,
    userSchools,
    setUserSchools,
    advancedSearchList,
    setAdvancedSearchList,
    CAPFilterDefaults,
    setCAPFilterDefaults,
    advancedFilter,
    setAdvancedFilter,
    refresh,
    setRefresh,
    filterWidth,
    setFilterWidth,
    errorResponse,
    setErrorResponse,
    advancedSearchListChecked,
    setAdvancedSearchListChecked,
    showBanner,
    setShowBanner,
    userOffers,
    setUserOffers,
    consultationAppointment,
    setConsultationAppointment,
    hasPurchasedConsultation,
    setHasPurchasedConsultation,
    barLoading,
    setBarLoading,
    percentLoaded,
    setPercentLoaded,
    loadedEfcs,
    loadedSchools,
    polling,
    setPolling,
    hasRecalculatedAllSchools,
    setHasRecalculatedAllSchools,
  };

  return (
    <AppContext.Provider value={sharedState}>{children}</AppContext.Provider>
  );
}

export function useAppContext() {
  return useContext(AppContext);
}
