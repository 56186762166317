import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useRouter } from "next/router";
import Link from "next/link";
import ClipLoader from "react-spinners/ClipLoader";
import queryString from "query-string";

import { CapLogo, LongLeftArrow } from "@icons/index";

import { useAppContext } from "@context/state";
import { setAccessToken } from "@utils/getAccessToken";
import GoogleLoginButton from "@components/Auth/GoogleLoginButton";
import {
  getReferralCodeFromSession,
  removeReferralCode,
} from "@utils/getReferralCode";
import { gtmPushAccountCreated } from "@utils/gtm";
import { EMAIL } from "@utils/constants";

import { registerUser } from "@api/auth";
import { captureUTM } from "@api/onboarding";

const CreateAccount = () => {
  const router = useRouter();

  const {
    setModalContent,
    handleApiError,
    setLoggedIn,
    scenario,
    setScenario,
    errorResponse,
    setErrorResponse,
    checkForNeededDashboardInfo,
  } = useAppContext();

  const methods = useForm({
    mode: "all",
    criteriaMode: "all", //allows multiple errors for one field
  });

  //test comment
  const {
    watch,
    trigger,
    register,
    formState: { isSubmitting, errors, isValid },
    handleSubmit,
    getValues,
  } = methods;

  const [showEmailErrors, setShowEmailErrors] = useState(false);
  const [showPasswordErrors, setShowPasswordErrors] = useState(false);

  //Required props: object with the data from all of the inputs in the multi step form.
  //Response will contain the onboarding data as well as efc and preapproval calculations
  //Response includes fresh jwt token which we will set to localStorage
  //If it fails, the token in localStorage will be set to empty and error messages will be set
  //to errorResponse state which is used to display error messages
  const onSubmit = async (values) => {
    //Only pass in email and password data from values, not confirm_password
    const registerParams = {};
    ["email", "password"].forEach((field) => {
      registerParams[field] = values[field];
    });
    //check for referral code
    const referralCode = getReferralCodeFromSession();
    if (referralCode) {
      registerParams["referral_code"] = referralCode;
    }
    try {
      // record UTM parameters
      const queries = queryString.parse(window.location.search);

      // capture utm parameters utm_content, utm_source, utm_medium, utm_campaign, utm_term from queries
      // and return the as an associative array of key value pairs
      const query = {
        utm_content: queries.utm_content ? queries.utm_content : "",
        utm_source: queries.utm_source ? queries.utm_source : "",
        utm_medium: queries.utm_medium ? queries.utm_medium : "",
        utm_campaign: queries.utm_campaign ? queries.utm_campaign : "",
        utm_term: queries.utm_term ? queries.utm_term : "",
      };
      const res2 = await captureUTM(
        registerParams.email,
        JSON.stringify(query)
      );
      const { data } = res2;
      const res = await registerUser(registerParams);
      if (res.data?.jwt) {
        //push event to Google Tag Manager
        gtmPushAccountCreated(EMAIL);
        //Set tokens
        localStorage.setItem("refresh_token", res.data.jwt?.refresh_token);
        setAccessToken(res.data.jwt?.access_token);
        setLoggedIn(true);
        //remove referral code if present
        if (getReferralCodeFromSession()) {
          removeReferralCode();
        }
        //set scenario for instant visual on name and efcs
        setScenario({
          ...scenario,
          case_id: res.data.case_id,
          onboarding: res.data.onboarding,
          efcs: res.data.efcs, //backend will return undefined here but that's okay for now
          preapprovals: res.data.preapprovals,
        });
        checkForNeededDashboardInfo(res.data.onboarding);
        router.push("/onboarding");
      }
    } catch (error) {
      //Only use error modal for unexpected errors
      if (error?.response?.status === 422) {
        //For example, email already used, password not acceptable
        setErrorResponse({
          status: error.response.status,
          ...error.response.data?.result,
        });
      } else {
        handleApiError(error);
      }
    }
  };

  useEffect(() => {
    trigger("email", "password");
    //Set content for help modal
    setModalContent([]);
    if (errorResponse) {
      //guarantee no server errors showing when initially load page
      setErrorResponse({});
    }
  }, []);

  useEffect(() => {
    trigger("password");
    trigger("confirm_password");
  }, [watch("password"), watch("confirm_password")]);

  return (
    <FormProvider {...methods}>
      <form
        className="create-account-form w-full h-full flex flex-col items-center text-left"
        action="#"
        method="post"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="navContainer onboardingNav">
          <div className="flex justify-between items-center w-full">
            <Link href="/" className="">
              <button type="button" className="simple leftBtn">
                <LongLeftArrow />
              </button>
            </Link>
          </div>
          <Link href="/" className="logo">
            <button type="button">
              <CapLogo />
            </button>
          </Link>
        </div>
        <div className="accountContainer onboardingScreen flex flex-col items-center justify-start text-center">
          <div className="accountText">
            <h1>Welcome</h1>
            <div className="p">
              Create your account to unlock billions of dollars of financial aid
              & scholarships
            </div>
          </div>
          <hr className="divider" />
          <h6 className="self-start">Email requirements</h6>
          <ul className="self-start mb-2">
            <li
              className={
                errorResponse?.email === "The email must be an unused email."
                  ? "textErrorState"
                  : ""
              }
            >
              Please use an email not already associated with a College Aid Pro
              account.
            </li>
          </ul>
          <h6 className="self-start">Password requirements</h6>
          <ul className="self-start mb-4">
            <li
              className={
                showPasswordErrors && errors.password?.types?.minLength
                  ? "textErrorState"
                  : ""
              }
            >
              <span>Minimum length: 8</span>
            </li>
            <li
              className={
                showPasswordErrors && errors.password?.types?.uppercasePresent
                  ? "textErrorState"
                  : ""
              }
            >
              <span>Uppercase characters: 1</span>
            </li>
            <li
              className={
                showPasswordErrors && errors.password?.types?.lowercasePresent
                  ? "textErrorState"
                  : ""
              }
            >
              <span>Lowercase characters: 1</span>
            </li>
            <li
              className={
                showPasswordErrors && errors.password?.types?.numberPresent
                  ? "textErrorState"
                  : ""
              }
            >
              <span>Numbers: 1</span>
            </li>
            {showPasswordErrors && errors.password && (
              <small className="errorMessage">Please review errors below</small>
            )}
            {errorResponse?.message && (
              // 5XX and 4XX errors
              <small className="errorMessage">{errorResponse.message}</small>
            )}
          </ul>
          <div className="inputs flex flex-col text-left">
            <label
              htmlFor="email"
              className="textLabel small"
              onChange={() => {
                if (errorResponse) {
                  //reset server errors when changing input
                  //putting it directly on the input messes with react hook form behavior
                  setErrorResponse({});
                }
              }}
            >
              <span className="labelFocus">Email Address</span>
              <input
                id="email"
                type="email"
                autoComplete="email"
                className={`${
                  ((showEmailErrors && errors.email) || errorResponse?.email) &&
                  "inputErrorState"
                }`}
                {...register("email", {
                  required: {
                    value: true,
                    message: "Invalid email address",
                  },
                  validate: {
                    correctFormat: (value) => /\S+@\S+/g.test(value),
                  },
                })}
                onBlur={() => setShowEmailErrors(true)}
              />
              {showEmailErrors && errors.email && (
                <small className="errorMessage">Invalid email address</small>
              )}
              {errorResponse?.email && (
                <small className="errorMessage">{errorResponse.email[0]}</small>
              )}
            </label>
            <label htmlFor="password" className="textLabel small">
              <span className="labelFocus">Set Password</span>
              <input
                id="password"
                type="password"
                autoComplete="new-password"
                className={`${
                  ((showPasswordErrors && errors.password) ||
                    errorResponse?.password) &&
                  "inputErrorState"
                }`}
                {...register("password", {
                  mode: "onBlur",
                  required: {
                    value: true,
                    message: "Please enter a password",
                  },
                  validate: {
                    minLength: (value) => value.length >= 8,
                    uppercasePresent: (value) => /(?=.*[A-Z])/g.test(value),
                    lowercasePresent: (value) => /(?=.*[a-z])/g.test(value),
                    numberPresent: (value) => /(?=.*\d)/g.test(value),
                  },
                })}
                onBlur={() => setShowPasswordErrors(true)}
              />
              {showPasswordErrors && errors.password && (
                <small className="errorMessage">
                  Password does not meet requirements
                </small>
              )}
              {errorResponse?.password && (
                <small className="errorMessage">
                  {errorResponse.password[0]}
                </small>
              )}
            </label>
            <label htmlFor="confirm_password" className="textLabel small">
              <span className="labelFocus">Confirm Password</span>
              <input
                id="confirm_password"
                type="password"
                className={`${
                  showPasswordErrors &&
                  errors.confirm_password?.types?.matchConfirm &&
                  "inputErrorState"
                }`}
                {...register("confirm_password", {
                  required: {
                    value: true,
                    message: "Please enter a password",
                  },
                  validate: {
                    matchConfirm: (value) => value === getValues("password"),
                  },
                })}
              />
              {showPasswordErrors &&
                errors.confirm_password?.types?.matchConfirm && (
                  <small className="errorMessage">
                    Password does not match
                  </small>
                )}
            </label>
          </div>
          <div className="terms mb-4">
            By submitting your information you agree to our{" "}
            <Link href="/Privacy Policy.pdf" target="_blank">
              <span>Privacy Policy</span>
            </Link>{" "}
            and{" "}
            <Link href="/Terms and Conditions.pdf" target="_blank">
              <span>Terms & Conditions</span>
            </Link>
          </div>
          <GoogleLoginButton className="mb-6" />
        </div>
        <div className="lockupContainer flex flex-row items-center justify-center text-center">
          {isSubmitting ? (
            <div className="createAccountBtn flex items-center justify-center">
              <ClipLoader
                size={25}
                css={{
                  borderColor: "var(--primary-color)",
                  borderBottomColor: "transparent",
                }}
              />
            </div>
          ) : (
            <button
              type="submit"
              className="createAccountBtn secondary"
              disabled={!isValid || isSubmitting}
            >
              Create Account
            </button>
          )}
        </div>
      </form>
    </FormProvider>
  );
};

export default CreateAccount;
