import { useAppContext } from "@context/state";
import { HELP_VIEW } from "@utils/constants";

/**
 * @param {array} helpContent - array of objects for the help modal to map
 * @param {string} className
 * @returns A round button with a ? symbol. Used for tips and opening the help modal
 */
const HelpButton = ({ helpContent, className, symbol }) => {
  const { setDisplayModal, setModalView, setModalContent } = useAppContext();

  const openHelpModal = () => {
    setModalContent(helpContent);
    setModalView(HELP_VIEW);
    setDisplayModal(true);
  };
  return (
    <button
      type="button"
      disabled={helpContent.length === 0}
      className={`help-btn tertiary rounded-full flex items-center justify-center ${
        className ? className : ""
      }`}
      onClick={openHelpModal}
    >
      <div>{ symbol ? symbol : '?'}</div>
    </button>
  );
};

export default HelpButton;
