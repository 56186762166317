import { useState, useEffect, useRef } from "react";
import Link from "next/link";
import _ from "lodash";

import { useRouter } from "next/router";
import { useAppContext } from "@context/state";
import { Collapsible, DropdownBtn, HelpButton } from "@components/index";
import { checkClickOutside } from "@utils/clickOutside";
import { PlayButton } from "@icons/index";
import { processUSD } from "@utils/costEstimates";
import NoInfoCTA from "../Homepage/NoInfoCTA";
import LowInfoCTA from "../Homepage/LowInfoCTA";
import { getTotalPersonalInfoWeightPercentage } from "@utils/calcEnhancedProfile";
import { EFC_LABEL } from "@utils/constants";

// Save for later use when we differentiate between free and premium users
// import BootCampBannerPremium from "../Homepage/BootCampBannerPremium";
// import BootCampBannerFreeForAll from "../Homepage/BootCampBannerFreeForAll";

const Dashboard = () => {
  const {
    scenario,
    upgraded,
    efcVideo,
    openEfcVideo,
    needMoreInfo,
    advancedSearchListChecked,
    setAdvancedSearchListChecked,
    showBanner,
  } = useAppContext();

  const { onboarding } = scenario;
  const router = useRouter();

  const schoolSearch = () => {
    // Set the search url to the school search page with the case id and default search params
    // state is xx because we aren't in the advanced search yet.
    const case_id = scenario?.case_id;
    const searchUrl = `/school_search/${case_id}/state=xx&10&offset=0&order=net-cost`;
    // Clear the advanced search list checked
    setAdvancedSearchListChecked([]);
    router.push(searchUrl);
  };

  const efcHelpModalContent = [
    {
      id: 0,
      title: `First Year Federal ${EFC_LABEL}`,
      description:
        "This number is used to determine your family's eligibility for need-based financial aid at FAFSA only schools. This predominately includes public colleges/universities.",
    },
    {
      id: 1,
      title: `First Year Institutional ${EFC_LABEL}`,
      description: (
        <span>
          This number is used to determine your family's eligibility for
          need-based financial aid at schools who require the CSS Profile in
          addition to the FAFSA. This predominately includes private
          colleges/universities.
          <br />
          <br />
          Schools that use the Institutional Methodology treat some factors
          (such as home equity) differently, which can result in a range of
          possible SAI values.
          <br />
        </span>
      ),
    },
    {
      id: 2,
      title: "First Year College Budget",
      description:
        "The dollar amount your family can pay for college before any borrowing.",
    },
  ];

  const [expanded, setExpanded] = useState(false);
  const [efcMenuOpen, setEfcMenuOpen] = useState(false);
  const efcMenuRef = useRef();

  const getEFC = (type) => {
    if (scenario?.efcs) {
      return scenario.efcs[`${type}_efc`];
    }
  };

  useEffect(() => {
    //expanded's initial state will be closed for desktop
    if (window?.innerWidth < 1024) {
      setExpanded(true);
    }
  }, []);

  const toggleEFCMenu = () => {
    if (efcMenuOpen) {
      setEfcMenuOpen(false);
    } else {
      setEfcMenuOpen(true);
    }
  };

  /**
   * Toggle LowInfoCTA
   * @param {*} profileData
   */
  const checkForNeededAdvancedDashboardInfo = (profileData) => {
    if (profileData) {
      if (sessionStorage.getItem("hide_cta") !== "true") {
        return (
          getTotalPersonalInfoWeightPercentage(profileData).percentComplete <
          100 ||
          !_.get(profileData, "student.scores.sat") ||
          !_.get(profileData, "student.scores.weighted_gpa")
        );
      }
    }
  };

  const handleClick = (event) => {
    const isOutside = checkClickOutside(event, efcMenuRef);
    if (isOutside) {
      setEfcMenuOpen(false);
    }
  };

  // When expanded state changes to true, add event listener to check for clicking outside search element
  useEffect(() => {
    if (efcMenuRef.current) {
      if (efcMenuOpen) {
        window.addEventListener("click", handleClick);
      } else {
        window.removeEventListener("click", handleClick);
      }
      return () => {
        window.removeEventListener("click", handleClick);
      };
    }
  }, [efcMenuOpen]);

  const institutionalLowerSai = processUSD(getEFC("institutional_lower"));
  const institutionalUpperSai = processUSD(getEFC("institutional_upper"));

  const whiteLabel = process.env.NEXT_PUBLIC_WHITELABEL;

  const federalSaiCopy = processUSD(getEFC("federal"));
  const insitutionalSaiCopy =
    institutionalLowerSai === institutionalUpperSai
      ? institutionalLowerSai
      : `${institutionalLowerSai} to ${institutionalUpperSai}`;

  const noValue = '--';

  // We might not have an approval amount (e.g. AGI has not been provided)
  const oneYearCollegeBudgetCopy = (scenario?.preapprovals?.preapproval == undefined ? noValue : processUSD(scenario?.preapprovals?.preapproval));

  const renderMobileSAIs = (
    <div className="lg:hidden">
      <Collapsible expanded={expanded}>
        <div className="efcContainer">
          <div className="efcInfo flex flex-col items-center">
            <div className="flex flex-row justify-between w-full">
              <div className="flex items-center">
                <small className="pr-2">{`${EFC_LABEL}s & Budget`}</small>
                <HelpButton
                  helpContent={efcHelpModalContent}
                  className="efcHelp small"
                />
              </div>
              <div>
                <Link href="/profile">

                  <small className="editBtn">Edit</small>

                </Link>
              </div>
            </div>
            <div className="efcData flex flex-row flex-nowrap justify-around w-full">
              <div className="flex flex-col text-center">
                <small>Federal</small>
                <span>{federalSaiCopy}</span>
              </div>
              <div className="flex flex-col text-center">
                <small>Institutional</small>
                <span>{insitutionalSaiCopy}</span>
              </div>
              <div className="flex flex-col text-center">
                <small>
                  One Year
                  <br />
                  College Budget
                </small>
                <span className="">{oneYearCollegeBudgetCopy}</span>
              </div>
            </div>
            <div className="efcText flex flex-row justify-center items-center w-full mb-6">
              <div className="mobileEfcTextContainer flex flex-col items-center">
                <small className="w-full">{efcVideo?.text1}</small>
                <small className="w-full">{efcVideo?.text2}</small>
              </div>
              <div className="flex flex-col justify-center items-start w-4">
                {whiteLabel !== "true" && (
                  <button
                    className="playButton w-5 m-2 absolute"
                    onClick={openEfcVideo}
                  >
                    <PlayButton />
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </Collapsible>
    </div>
  );

  return (
    <div className="dashboardContainer w-full">
      <div className="dashboardInfo flex flex-col m-2 mt-4 mb-2 lg:mb-2 lg:mt-4">
        <div className="nameplate h1 flex flex-row items-center lg:hidden">
          <div
            className="flex flex-row items-center cursor-pointer"
            onClick={() => setExpanded((cur) => !cur)}
          >
            <div>{scenario.onboarding?.student?.student_name || "Student"}</div>
            <DropdownBtn expanded={expanded} />
          </div>
        </div>
        {renderMobileSAIs}
        <div className="efcContainer mb-4 hidden lg:flex items-center">
          <div className="flex flex-row flex-nowrap w-full justify-center">
            <div className="flex flex-row justify-end items-end">
              <div className="sai-label">
                <small>{`Federal ${EFC_LABEL}`}</small>
                <span className="h2 removeMargin">
                  {needMoreInfo ? noValue : federalSaiCopy}
                </span>
              </div>
              <div className="sai-label">
                <small>{`Institutional ${EFC_LABEL}`}</small>
                <span className="h2 removeMargin">
                  {needMoreInfo ? noValue : insitutionalSaiCopy}
                </span>
              </div>
              <div className="sai-label">
                <small>
                  First Year College <br className="xl:hidden" />
                  Budget
                </small>
                <span className="h2 removeMargin">
                  {oneYearCollegeBudgetCopy}
                </span>
              </div>
              <div className="efcMenu ml-4 mr-3">
                <ul
                  className={`${efcMenuOpen ? "flex" : "hidden"
                    } flex-col items-center`}
                  ref={efcMenuRef}
                >
                  <li>
                    <Link href="/profile">

                      <small className="editBtn">Edit</small>

                    </Link>
                  </li>
                  <li>
                    <HelpButton
                      helpContent={efcHelpModalContent}
                      className="efcHelp small"
                    />
                  </li>
                </ul>
                <button
                  className={`dots ${efcMenuOpen ? "invertDots" : ""}`}
                  onClick={() => {
                    toggleEFCMenu();
                  }}
                >
                  <div className="rounded-full" />
                  <div className="rounded-full" />
                  <div className="rounded-full" />
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="efcText flex-col justify-center items-center w-full mb-6 px-5 hidden lg:flex">
          {/* Save this place for future boot camps where we need to differentiate between free and premium users */}
          {/* {showBanner && upgraded && !whiteLabel ? (
            <BootCampBannerPremium />
          ) : showBanner && !whiteLabel ? (
            <BootCampBannerFreemium />
          ) : null} */}
          {/* {showBanner && !whiteLabel ? <BootCampBannerFreeForAll /> : null} */}
          {needMoreInfo ? (
            <NoInfoCTA />
          ) : (
            <div className="flex flex-col">
              <div className="flex justify-center items-center">
                <div className="flex flex-col">
                  <small className="w-full">{efcVideo?.text1}</small>
                  <small className="w-full">{efcVideo?.text2}</small>
                </div>

                <div className="learnMoreBtn">
                  {whiteLabel !== "true" && (
                    <button
                      className="tertiary-dark highlight h-full ml-3 flex flex-row items-center flex-nowrap"
                      onClick={openEfcVideo}
                    >
                      <span className="px-1 whitespace-nowrap">Learn More</span>
                      <div className="w-4 pr-1">
                        <PlayButton />
                      </div>
                    </button>
                  )}
                </div>
              </div>
              {onboarding && (
                <>
                  {checkForNeededAdvancedDashboardInfo(onboarding) && (
                    <LowInfoCTA />
                  )}
                </>
              )}
            </div>
          )}
        </div>
        <div className="schoolSearchContainer flex justify-center">
          <button className="schoolSearchBtn primary" onClick={schoolSearch}>
            Search For Schools
          </button>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
