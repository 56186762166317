/**
 * Interpolate vimeo link
 * @param {string} videoId
 * @param {string} hash - hash parameter (h) that has to be appended to private video URLs in order to be embedded.
 * @returns
 */
const capVimeoLink = (videoId, hash) => {
  return `https://player.vimeo.com/video/${videoId}?h=${hash}&amp;badge=0&amp;autoplay=1&amp;player_id=0&amp;app_id=58479`;
};

export const UNPROTECTED_PATHS = [
  "/create_account",
  "/login",
  "/reset_password",
  "/",
];
export const AUTH_NAV_PATHS = ["/create_account", "/login", "/reset_password"];
export const IEC_RESTRICTED_PATHS = [
  "/profile",
  "/personal_info",
  "/account_info",
  "/college_pre_approval",
];
export const HELP_VIEW = "HELP_VIEW";
export const ONBOARDING_VIEW = "ONBOARDING_VIEW";
export const OUTCOMES_VIEW = "OUTCOMES_VIEW";
export const PREMIUM_VIEW = "PREMIUM_VIEW";
export const EXPIRATION_VIEW = "EXPIRATION_VIEW";
export const CANCELLATION_CONFIRMATION_VIEW = "CANCELLATION_CONFIRMATION_VIEW";
export const CANCELLATION_SUCCESS_VIEW = "CANCELLATION_SUCCESS_VIEW";
export const SUBSCRIPTION_RENEWAL = "SUBSCRIPTION_RENEWAL";
export const SCHOOL_CARD_EDIT = "SCHOOL_CARD_EDIT";
export const SCHOOL_MORE_INFO = "SCHOOL_MORE_INFO";
export const CHANGE_EMAIL = "CHANGE_EMAIL";
export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const CANCEL_SUBSCRIPTION = "CANCEL_SUBSCRIPTION";
export const PERSONAL_INFO_EDIT = "PERSONAL_INFO_EDIT";
export const STUDENT_INFO_EDIT = "STUDENT_INFO_EDIT";
export const COLLEGE_PRE_APPROVAL_EDIT = "COLLEGE_PRE_APPROVAL_EDIT";
export const OFFER_EDIT = "OFFER_EDIT";
export const PENDING = "PENDING";
export const READY = "READY";
export const NOT_SUPPORTED = "NOT_SUPPORTED";
export const YOUTUBE_CHANNEL = "https://member.collegeaidpro.com/";
export const FAFSA_VIDEO = "https://www.youtube.com/watch?v=5eopOZlGUok";
export const CSS_VIDEO = "https://youtu.be/h3oTF-wwaWg?si=hCy14i2mDGuYMXMi";
export const FB_GROUP =
  "https://www.facebook.com/groups/collegeaidprospayingforcollegeandscholarships";
export const SSO_SCHOLARSHIPS_PATH = "/scholarships ";
export const YES = "Yes";
export const NO = "No";
export const FREEMIUM_PRICE_ID = "Freemium";
export const MYCAP_PREMIUM_PRICE_ID_MONTHLY = "MyCAP-Premium-USD-Monthly";
export const MYCAP_PREMIUM_PRICE_ID_YEARLY = "MyCAP-Premium-USD-Yearly";
export const MYCAP_PREMIUM_PLUS_PRICE_ID =
  "MyCAP-Premium-Industry-Expert-Meeting-USD-Yearly";
export const MYCAP_PREMIUM_RENEWAL_PRICE_ID =
  "MyCAP-Premium-Renewal-USD-Monthly";
export const MYCAP_PREMIUM_PLUS_RENEWAL_PRICE_ID =
  "MyCAP-Premium-Plus-Renewal-USD-Monthly";
export const COMING_SOON_VIEW = "COMING_SOON";
export const WALKTHROUGH_VIDEO = capVimeoLink("644357004", "6b86de7b2b");
export const EFC_VIDEO_1 = capVimeoLink("656167530", "418a6a3901");
export const EFC_VIDEO_2 = capVimeoLink("656167762", "947c0c9481");
export const EFC_VIDEO_3 = capVimeoLink("656168074", "3b2c5a5058");
export const EFC_VIDEO_4 = capVimeoLink("656168365", "38249c9145");
export const EFC_VIDEO_5 = capVimeoLink("656168781", "8dbc77545d");
export const EFC_VIDEO_6 = capVimeoLink("656169064", "74bf4d8428");
export const EFC_VIDEO_7 = capVimeoLink("656169948", "499bfd5ea1");
export const EFC_VIDEO_8 = capVimeoLink("656170180", "6926efef48");
export const EFC_VIDEO_9 = capVimeoLink("656721576", "64c5d3cf9b");
export const EFC_VIDEO_10 = capVimeoLink("656721797", "d90569979b");
export const SEARCH_ACCEPTABLE_CHARS_REGEXP = /^[a-zA-Z0-9-\s&]+$/g;
export const CALCULATE_JOB_ADDED_TO_QUEUE = "calculate job added to queue";
export const EXPERT_BUTTON_TEXT = "Talk to an Expert";
export const EMAIL = "email";
export const GOOGLE = "google";
export const EFC_LABEL = "SAI";

// Where to go after logging out from a MyCAP account
export const POST_LOGOUT_URL = "https://www.collegeaidpro.com/";
