import { DownArrow } from "@icons/index";
import { HelpButton } from "@components/index";

const CAPSelect = ({ register, title, field, options, defaultValue, helpInfo }) => {
  return (
    <label htmlFor={field} className="selectLabel small">
      <div>{title}
      { helpInfo &&
        <HelpButton
          helpContent={[
            {
              id: 0,
              title: title,
              description: helpInfo
            },
          ]}
          className="small right"
        />
      }
      </div>
      <select
        id={field}
        {...register(field)}
        defaultValue = {defaultValue}>
        {options.map((option, index) => (
          <option key={index} value={option.value}>
            {option.label}
          </option>
        ))}

      </select>
      <div className="select-icon md:flex">
        <DownArrow />
      </div>
    </label>
  );
};

export default CAPSelect;
