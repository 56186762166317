import axiosInstance from "@api/axiosInstance";

/******************************************/
/************ --- ONBOARDING  --- ************/
/******************************************/

/* Endpoint to send email verification url and code.*
 * @params {string} email
 * @params {object} utmParams - the utm parameters that identify where the user came from and will be passed to hubspot
 * returns: boolean
 */
export const captureUTM = async (email, utmParams) => {
  const data = {
    email: email,
    payload: utmParams,
  };
  const res = await axiosInstance.post(`/capture-utm`, data);
  return res;
};
