import { useState, useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { useAppContext } from "@context/state";
import { useRouter } from "next/router";
import { PREMIUM_VIEW } from "@utils/constants";
import { CAPAdvancedSchoolSearch } from "@api/schools";

import React from "react";

import CAPSelect from "./CAPSelect";
import CAPScoreSelect from "./CAPScoreSelect";

import ClipLoader from "react-spinners/ClipLoader";
import { getMajors } from "@api/misc";
import { getGroupings } from "@api/misc";
import _ from "lodash";


const CAPFilter = ({
  sortingBy,
  setSortingBy,
  setPageNumber,
  advancedSearchList,
  setAdvancedSearchList,
  curatedSearchList,
  setCuratedSearchList,
  show
}) => {
  const router = useRouter();
  const filterContainerRef = useRef(null);
  const { upgraded, setDisplayModal, setModalView } = useAppContext();
  const {
    scenario,
    CAPFilterDefaults,
    setCAPFilterDefaults,
    setAdvancedFilter,
    setInitialExpanded,
    polling,
    handleApiError,
  } = useAppContext();

  const {
    register,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm({
    mode: "all",
    defaultValues: CAPFilterDefaults, //useMemo necessary?
    criteriaMode: "all", //allows multiple errors for one field
  });

  const [majors, setMajors] = useState([]);
  const [groupings, setGroupings] = useState([]);

  useEffect(() => {
    CAPFilterDefaults.show = true;
  });

  //For sticker price filter, see commit fa4ded4d971074661e803cd2d4a7909eed816041
  const createFilters = (values) => {
    let filter = "";
    if (!fileIsEmpty(values.distance)) {
      filter = filter.concat(`distance=${values.distance}&`);
    }
    if (!fileIsEmpty(values.state)) {
      filter = filter.concat(`state=${values.state}&`);
    }
    if (!fileIsEmpty(values.enrollment)) {
      filter = filter.concat(`size=${values.enrollment}&`);
    }
    if (!fileIsEmpty(values.type)) {
      filter = filter.concat(`type=${values.type}&`);
    }
    if (!fileIsEmpty(values.major)) {
      filter = filter.concat(`major=${values.major}&`);
    }
    if (!fileIsEmpty(values.forbes_ranking)) {
      filter = filter.concat(`ranking=${values.forbes_ranking}&`);
    }
    if (!fileIsEmpty(values.funding_gap)) {
      filter = filter.concat(`gap=${values.funding_gap}&`);
    }
    if (!fileIsEmpty(values.net_cost)) {
      filter = filter.concat(`net_cost=${values.net_cost}&`);
    }
    if (!fileIsEmpty(values.scholarship)) {
      filter = filter.concat(`scholarship=${values.scholarship}&`);
    }
    if (!fileIsEmpty(values.affordability)) {
      filter = filter.concat(`affordability=${values.affordability}&`);
    }

    // New advanced search elenents
    if (!fileIsEmpty(values.region)) {
      filter = filter.concat(`region=${values.region}&`);
    }
     if (!fileIsEmpty(values.funding)) {
      filter = filter.concat(`funding=${values.funding}&`);
    }
    if (!fileIsEmpty(values.admissions_likelihood)) {
      filter = filter.concat(`admissions_likelihood=${values.admissions_likelihood}&`);
    }
    if (!fileIsEmpty(values.min_gpa)) {
      filter = filter.concat(`min_gpa=${values.min_gpa}&`);
    }
    if (!fileIsEmpty(values.act_percentile)) {
      filter = filter.concat(`act_percentile=${values.act_percentile}&act_percentile_checkbox=${values.act_percentile_checkbox}&`);
    }
    if (!fileIsEmpty(values.sat_percentile)) {
      filter = filter.concat(`sat_percentile=${values.sat_percentile}&sat_percentile_checkbox=${values.sat_percentile_checkbox}&`);
    }
    if (!fileIsEmpty(values.common_app)) {
      filter = filter.concat(`common_app=${values.common_app}&`);
    }
    if (!fileIsEmpty(values.test_optional)) {
      filter = filter.concat(`test_optional=${values.test_optional}&`);
    }
    if (!fileIsEmpty(values.money_ranking)) {
      filter = filter.concat(`money_ranking=${values.money_ranking}&`);
    }
    if (!fileIsEmpty(values.sai)) {
      filter = filter.concat(`sai=${values.sai}&`);
    }
    if (!fileIsEmpty(values.merit_transparency)) {
      filter = filter.concat(`merit_transparency=${values.merit_transparency}&`);
    }
    if (!fileIsEmpty(values.salary)) {
      filter = filter.concat(`salary=${values.salary}&`);
    }
    if (!fileIsEmpty(values.full_ride)) {
      filter = filter.concat(`full_ride=${values.full_ride}&`);
    }
    if (!fileIsEmpty(values.full_tuition)) {
      filter = filter.concat(`full_tuition=${values.full_tuition}&`);
    }
    if (!fileIsEmpty(values.grouping)) {
      filter = filter.concat(`grouping=${values.grouping}&`);
    }

    filter = filter.concat(`limit=10`);
    //set filter to state to use for sorting
    setAdvancedFilter(filter);
    return filter + "&offset=0" + "&order=net-cost";
  };

  const fileIsEmpty = (field) => {
    return field === "null" || field === "0" || _.isEmpty(field);
  };

  const onSubmit = async (formValues) => {
    const myFilters = createFilters(formValues);
    setInitialExpanded(false);
    setCAPFilterDefaults(formValues);
    const tmpArray = [1, 2, 3];
    setCuratedSearchList(tmpArray);
    const bb = curatedSearchList.length;
    setCuratedSearchList([]);

    try {
      if (upgraded) {
        const res = await CAPAdvancedSchoolSearch(
          scenario?.case_id,
          myFilters
        );
        if (res?.data?.results) {
          router.push(
            `/school_search/${scenario?.case_id}/${myFilters}`
          );
          //   //reset page number back to 1
          setPageNumber(1);
          setAdvancedSearchList([]);
          setAdvancedSearchList(res.data.results);
        }
      } else {
        setModalView(PREMIUM_VIEW);
        setDisplayModal(true);
      }
    } catch (error) {
      handleApiError(error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await getMajors();
        if (res.data) {
          res.data[0].name = "Any Major";
          setMajors(
            res.data.map((major) => {
              return { label: major.name, value: major.id };
            })
          );
        }
      } catch (error) {
        //This is a public route, so 401 or 403 errors are not expected.
        handleApiError(error);
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await getGroupings();
        if (res.data) {
          res.data[0].name = "Any Grouping";
          setGroupings(
            res.data.map((grouping) => {
              return { label: grouping.name, value: (grouping.name == res.data[0].name ? '' : grouping.name) };
            })
          );
        }
      } catch (error) {
        //This is a public route, so 401 or 403 errors are not expected.
        handleApiError(error);
      }
    }
    fetchData();
  }, []);

  // Shortcuts for accessing some scenario properties
  const student = scenario?.onboarding?.student;
  const scores = student?.scores;

  /*
  // If we have the student and majors, display the major name
  var major_name;
  const majors_list = document.querySelectorAll('#major option');
  if (student && (majors_list.length > 0)) {
    console.log(student.major_id, majors_list);
    majors_list.forEach(function(opt) {
      if (student.major_id == opt.value) {
        major_name = opt.innerText;
      }
    });
  }
  */

  const distances = [
    { label: "Any Distance", value: "null" },
    { label: "=< 100 Miles", value: "100" },
    { label: "=< 200 Miles", value: "200" },
    { label: "=< 500 Miles", value: "500" },
    { label: "=< 1,000 Miles", value: "1000" },
  ];

  const states = [
    { label: "Any State", value: "null" },
    { label: "Alabama", value: "AL" },
    { label: "Alaska", value: "AK" },
    { label: "American Samoa", value: "AS" },
    { label: "Arizona", value: "AZ" },
    { label: "Arkansas", value: "AR" },
    { label: "California", value: "CA" },
    { label: "Colorado", value: "CO" },
    { label: "Connecticut", value: "CT" },
    { label: "Delaware", value: "DE" },
    { label: "District of Columbia", value: "DC" },
    { label: "Federated States of Micronesia", value: "FSM" },
    { label: "Florida", value: "FL" },
    { label: "Georgia", value: "GA" },
    { label: "Guam", value: "GU" },
    { label: "Hawaii", value: "HI" },
    { label: "Idaho", value: "ID" },
    { label: "Illinois", value: "IL" },
    { label: "Indiana", value: "IN" },
    { label: "Iowa", value: "IA" },
    { label: "Kansas", value: "KS" },
    { label: "Kentucky", value: "KY" },
    { label: "Louisiana", value: "LA" },
    { label: "Maine", value: "ME" },
    { label: "Marshall Islands", value: "MH" },
    { label: "Maryland", value: "MD" },
    { label: "Massachusetts", value: "MA" },
    { label: "Michigan", value: "MI" },
    { label: "Minnesota", value: "MN" },
    { label: "Mississippi", value: "MS" },
    { label: "Missouri", value: "MO" },
    { label: "Montana", value: "MT" },
    { label: "Nebraska", value: "NE" },
    { label: "Nevada", value: "NV" },
    { label: "New Hampshire", value: "NH" },
    { label: "New Jersey", value: "NJ" },
    { label: "New Mexico", value: "NM" },
    { label: "New York", value: "NY" },
    { label: "North Carolina", value: "NC" },
    { label: "North Dakota", value: "ND" },
    { label: "Northern Mariana Islands", value: "MP" },
    { label: "Ohio", value: "OH" },
    { label: "Oklahoma", value: "OK" },
    { label: "Oregon", value: "OR" },
    { label: "Palua", value: "PW" },
    { label: "Pennsylvania", value: "PA" },
    { label: "Puerto Rico", value: "PR" },
    { label: "Rhode Island", value: "RI" },
    { label: "South Carolina", value: "SC" },
    { label: "South Dakota", value: "SD" },
    { label: "Tennessee", value: "TN" },
    { label: "Texas", value: "TX" },
    { label: "Utah", value: "UT" },
    { label: "Vermont", value: "VT" },
    { label: "Virgin Islands", value: "VI" },
    { label: "Virginia", value: "VA" },
    { label: "Washington", value: "WA" },
    { label: "West Virginia", value: "WV" },
    { label: "Wisconsin", value: "WI" },
    { label: "Wyoming", value: "WY" },
  ];

  const enrollments = [
    { label: "Any Enrollment Size", value: "null" },
    { label: "< 5,000", value: "small" },
    { label: "> 5,000 =< 15,000", value: "medium" },
    { label: "> 15,000", value: "large" },
  ];

  //Institution type filter
  const institutionTypes = [
    { label: "Any Type", value: "null" },
    { label: "Two-Year Institution", value: "two-year" },
    { label: "Four-Year Institution", value: "four-year" },
  ];

  //Forbes Ranking filter. Forbes rankings go up to 600
  const forbesRankings = [
    { label: "Any Ranking", value: "null" },
    { label: "Top 10", value: "10" },
    { label: "Top 50", value: "50" },
    { label: "Top 100", value: "100" },
    { label: "Just Ranked Schools", value: "600" },
  ];

  //For sticker price filter, see commit fa4ded4d971074661e803cd2d4a7909eed816041

  //affordability is actually filtered based on funding gap, so a numeric value is needed to filter results
  const fundingGaps = [
    { label: "Any Amount", value: "null" },
    { label: "=< $10,000", value: "10000" },
    { label: "=< $27,000", value: "27000" },
    {
      label: "=< $55,000", value: "55000",
    },
  ];

  const netCosts = [
    { label: "Any Net Cost", value: "null" },
    { label: "=< $10,000", value: "10000" },
    { label: "=< $25,000", value: "25000" },
    { label: "=< $40,000", value: "40000" },
    { label: "=< $55,000", value: "55000" },
  ];

  const meritScholarships = [
    { label: "No Filter", value: "null" },
    { label: "Any Scholarship", value: "*" },
    { label: "Automatic Only", value: "A" },
  ];

  const affordabilityProfiles = [
    { label: "No Filter", value: "null" },
    { label: "Ideal: No loans", value: "ideal" },
    { label: "Ideal and Great: Loans under $27K", value: "great" },
    { label: "Ideal, Great and Good: Loans under first year salary", value: "good" },
  ];

  // New advanced search elements
  const regions = [
    { label: "Any Region", value: "null" },
    { label: "Far West", value: "far west" },
    { label: "Great Lakes", value: "great lakes" },
    { label: "Mid East", value: "mid east" },
    { label: "New England", value: "new england" },
    { label: "Plains", value: "plains" },
    { label: "Rocky Mountains", value: "rocky mnountains" },
    { label: "Southeast", value: "southeast" },
    { label: "Southwest", value: "southwest" },
    { label: "Outlying Areas", value: "outlying areas" },
    { label: "Canada", value: "canada" },
  ];

  const fundings = [
    { label: "Any Funding Type", value: "null" },
    { label: "Public", value: "public" },
    { label: "Private", value: "private" },
  ];

  const moneyRankings = [
    { label: "Any Ranking", value: "null" },
    { label: "5 stars only", value: "5" },
    { label: "4.5 to 5", value: "4.5" },
    { label: "4 to 5", value: "4.0" },
    { label: "3.5 to 5", value: "3.5" },
    { label: "3 to 5", value: "3.0" },
    { label: "2.5 to 5", value: "2.5" },
  ];

  const admissionsLikelihoods = [
    { label: "Any Value", value: "null" },
    { label: "Highly Selective", value: "Highly Selective" },
    { label: "Selective", value: "Selective" },
    { label: "Competitive", value: "Competitive" },
    { label: "High Acceptance", value: "High Acceptance" },
  ];

  const minGPAs = [
    { label: "No Filter", value: "null" },
    { label: "My Unweighted GPA ≥ Entering Freshman Average", value: "unweighted" },
    // { label: "My Weighted GPA ≥ Entering Freshman Average", value: "weighted" },
  ];

  const satPercentiles = [
    { label: "Top 25% of SAT scores", value: "25" },
    { label: "Top 50% of SAT scores", value: "50" },
    { label: "Top 75% of SAT scores", value: "75" },
  ];

  const actPercentiles = [
    { label: "Top 25% of ACT scores", value: "25" },
    { label: "Top 50% of ACT scores", value: "50" },
    { label: "Top 75% of ACT scores", value: "75" },
  ];

  const commonApps = [
    { label: "No Filter", value: "null" },
    { label: "Uses the Common App", value: "yes" },
    { label: "Does not use the Common App", value: "no" },
  ];

  const testOptionals = [
    { label: "No Filter", value: "null" },
    { label: "Test scores are optional", value: "yes" },
    { label: "Test scores ARE NOT optional", value: "no" },
  ];

  const sais = [
    { label: "No Filter", value: "null" },
    { label: "FAFSA-Only", value: "federal" },
    { label: "Institutional", value: "institutional" },
  ];

  const meritTransparencies = [
    { label: "No Filter", value: "null" },
    { label: "A", value: "A" },
    { label: "A and B", value: "B" },
    { label: "A, B and C", value: "C" },
    { label: "A, B, C and D", value: "D" },
    { label: "A, B, C, D and F", value: "F" },
    { label: "A through F and N/A", value: "N/A" },
  ];

  const salaries = [
    { label: "No Filter", value: "null" },
    { label: "$100K and above", value: "100000" },
    { label: "$80K and above", value: "80000" },
    { label: "$60K and above", value: "60000" },
    { label: "$40K and above", value: "40000" },
  ];

  const fullRides = [
    { label: "No Filter", value: "null" },
    { label: "Yes, college has full ride scholarships", value: "yes",  },
    { label: "No, college DOES NOT have full ride scholarships", value: "no" },
  ];

  const fullTuitions = [
    { label: "No Filter", value: "null" },
    { label: "Yes, college has full tuition scholarships", value: "yes",  },
    { label: "No, college DOES NOT have full tuition scholarships", value: "no" },
  ];

  let myStyle = { display: "none" };
  if (show) {
    myStyle = { display: "flex" };
  }

  return (
    <div className="formContainer flex flex-col flew-wrap" ref={filterContainerRef} style={myStyle}>
      <form className="filterForm" onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-col flex-1 flex-wrap w-full">
          <div className="filters felx flex-col flex-1 md:justify-between flex-wrap w-full">
            <div className="selectContainer flex flex-col w-full">
              <div className="wrapper">

                <details>
                  <summary>Type</summary>
                  <CAPSelect
                    register={register}
                    title="Institution Type"
                    field="type"
                    options={institutionTypes}
                  />
                  <CAPSelect
                    register={register}
                    title="Enrollment"
                    field="enrollment"
                    options={enrollments}
                  />
                  <CAPSelect
                    register={register}
                    title="Public/Private"
                    field="funding"
                    options={fundings}
                  />
                  <CAPSelect
                    register={register}
                    title="Special Groupings"
                    field="grouping"
                    options={groupings}
                  />
                </details>

                <details>
                  <summary>Location</summary>
                  <CAPSelect
                    register={register}
                    title="Miles From Home"
                    field="distance"
                    options={distances}
                  />
                  <CAPSelect
                    register={register}
                    title="State"
                    field="state"
                    options={states}
                  />          
                  <CAPSelect
                    register={register}
                    title="Region"
                    field="region"
                    options={regions}
                  />
                </details>

                <details>
                  <summary>Financial</summary>
                  <CAPSelect
                    register={register}
                    title="Your Net Cost"
                    field="net_cost"
                    options={netCosts}
                  />
                  <CAPSelect
                    register={register}
                    title="Merit Scholarship"
                    field="scholarship"
                    options={meritScholarships}
                  />
                  <CAPSelect
                    register={register}
                    title="Affordability Profile"
                    field="affordability"
                    options={affordabilityProfiles}
                  />
                  <CAPSelect
                    register={register}
                    title="Funding Gap"
                    field="funding_gap"
                    options={fundingGaps}
                  />
                  <CAPSelect
                    register={register}
                    title="SAI Basis"
                    field="sai"
                    options={sais}
                  />
                  <CAPSelect
                    register={register}
                    title="Merit Transparency"
                    field="merit_transparency"
                    options={meritTransparencies}
                    helpInfo={<React.Fragment>
                      <ul className="ml-1 md:ml-1.5">
                        <li>A: Merit amounts and criteria clearly specified on school website (not wide dollar ranges, actual amounts)</li>
                        <li>B: School website does not clearly disclose both amounts and criteria for each, but merit is included on NPC with actual dollar amounts (not wide ranges)</li>
                        <li>C: We can only get to a very wide dollar range, but we understand the criteria that is used to award specific automatic/semi-automatic merit scholarships</li>
                        <li>D: Data is very limited, school seems to offer automatic/semi-automatic merit, we can only find amounts/ranges OR criteria, not both; we can use actual awards to hone in on it better</li>
                        <li>F: No amounts listed on school website, no merit on NPC, but we are pretty sure they offer automatic/semi-automatic merit awards</li>
                        <li>N/A: School only awards competitive scholarships, merit scholarships are rare or non-existent at the school</li>
                        <li>-: School not yet analyzed for merit transparency</li>
                      </ul>
                    </React.Fragment>}
                  />
                  <CAPSelect
                    register={register}
                    title="Starting Salary"
                    field="salary"
                    options={salaries}
                  />
                  <CAPSelect
                    register={register}
                    title="Full Ride Scholarships"
                    field="full_ride"
                    options={fullRides}
                  />
                  <CAPSelect
                    register={register}
                    title="Full Tuition Scholarship"
                    field="full_tuition"
                    options={fullTuitions}
                  />
                </details>

                <details>
                  <summary>Academics</summary>
                  <CAPSelect
                    register={register}
                    title="Major"
                    field="major"
                    options={majors}
                  />
                  <CAPSelect
                    register={register}
                    title="Selectivity"
                    field="admissions_likelihood"
                    options={admissionsLikelihoods}
                    helpInfo={<React.Fragment>
                      Admissions Selectivity is determined by the acceptance percentages published by the school:
                      <br></br>
                      <br></br>
                      <ul className="ml-1 md:ml-1.5">
                        <li>Highly Selective: 20% or lower acceptance rate</li>
                        <li>Selective: 21% to 40%</li>
                        <li>Competitive: 41% to 80%</li>
                        <li>High Acceptance: 81% and higher</li>
                      </ul>
                    </React.Fragment>}
                  />
                  <CAPSelect
                    register={register}
                    title={"GPA (" + (scores?.weighted_gpa ?? 'n/a') + ")"}
                    field="min_gpa"
                    options={minGPAs}
                  />
                  <CAPScoreSelect
                    register={register}
                    title={"SAT (" + (scores?.sat ?? 'n/a') + ")"}
                    field="sat_percentile"
                    label="Include schools with no SAT requirements..."
                    options={satPercentiles}
                    helpInfo={<React.Fragment>
                      This filter compares the SAT score in your profile to the published SAT ranges of accepted students provided by the colleges.
                    </React.Fragment>}
                  />
                  <CAPScoreSelect
                    register={register}
                    title={"ACT (" + (scores?.act ?? 'n/a') + ")"}
                    field="act_percentile"
                    label="Include schools with no ACT requirements..."
                    options={actPercentiles}
                  />
                  <CAPSelect
                    register={register}
                    title="Common App"
                    field="common_app"
                    options={commonApps}
                  />
                  <CAPSelect
                    register={register}
                    title="Test Optional"
                    field="test_optional"
                    options={testOptionals}
                  />
                </details>

                <details>
                  <summary>Rankings</summary>
                  <CAPSelect
                    register={register}
                    title="Forbes Ranking"
                    field="forbes_ranking"
                    options={forbesRankings}
                  />
                  <CAPSelect
                    register={register}
                    title="Money Ranking"
                    field="money_ranking"
                    options={moneyRankings}
                  />
                </details>
                
              </div>

              <div className="lockupContainer1 flex flex-row place-items-end text-center">
                {isSubmitting || polling ? (
                  <div className="m-auto">
                    <ClipLoader
                      size={25}
                      css={{
                        borderColor: "var(--primary-color)",
                        borderBottomColor: "transparent",
                      }}
                      speedMultiplier={0.8}
                    />
                  </div>
                ) : (
                  <button
                    type="submit"
                    className={"secondary"}
                    disabled={isSubmitting || polling}
                  >
                    Search
                  </button>
                )}
              </div>

              <div className="resetContainer flex flex-row place-items-end text-center">
                {isSubmitting || polling ? (
                  <div className="m-auto">
                    <ClipLoader
                      size={25}
                      css={{
                        borderColor: "var(--primary-color)",
                        borderBottomColor: "transparent",
                      }}
                      speedMultiplier={0.8}
                    />
                  </div>
                ) : (
                  <button
                    type="reset"
                    className={"tertiary"}
                    disabled={isSubmitting || polling}
                  >
                    Reset
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default CAPFilter;
