import Link from "next/link";
import { useAppContext } from "@context/state.js";
import renderLogo from "@utils/renderLogo.js";
import { processNetCost, processCostOfAttendance } from "@utils/costEstimates";
import { Cross } from "@icons/index";
import { removeSchoolFromUser } from "@api/schools";
import { PREMIUM_VIEW } from "@utils/constants";
import { Card } from "@components/index";
import { getAdminAccessTokenFromCookies } from "@utils/getAccessToken";
import { HelpButton } from "@components/index";

const SchoolCard = ({ schoolData }) => {
  if (!schoolData || !schoolData?.visible) {
    return null;
  }
  const { school_id, name, logo, breakdowns } = schoolData;
  const {
    scenario,
    upgraded,
    populateUserSchools,
    setModalView,
    setDisplayModal,
    handleApiError,
    handleAdminToken,
    needMoreInfo,
  } = useAppContext();

  const schoolPath = `/case/${scenario.case_id}/school/${school_id}`;

  const removeSchool = async () => {
    try {
      const res = await removeSchoolFromUser(scenario?.case_id, school_id);
      if (res) {
        //update state for user schools
        populateUserSchools(scenario.case_id);
      }
    } catch (error) {
      handleApiError(error);
    }
  };

  const openPremiumModal = () => {
    setModalView(PREMIUM_VIEW);
    setDisplayModal(true);
  };

  return (
    <div className="cardContainer">
      <Card type="typeTall">
        <div className="flex flex-col justify-between h-full">
          <div className="topHeading">
            <div className="flex flex-row justify-between items-start">
              {renderLogo(logo, name)}
              <Link href={schoolPath} className="flex items-start small font-demi-bold">
                
                  More Info
                
              </Link>
            </div>
            <div className="h4">
              <Link href={schoolPath} className="underline inherit">
                {name}
              </Link>
            </div>
          </div>
          <div className="flex flex-col">
            <div className="divider" />
            <div className="price">
              <div className="quote flex flex-col">
                { needMoreInfo &&
                  <div className="need-more-info">
                    { 
                      <HelpButton
                        helpContent={[
                          {
                            id: 0,
                            title: "Required Data Needed",
                            description: "Without Student's Zip Code, High School Graduation Year, Unweighted GPA, Parent/Guardian Income, and Non-Retirement Investments, we are unable to provide base estimates of SAI and net cost. All net cost projections will include zero financial aid until those data points are provided.\n\nPlease estimate these values if you do not have them handy. You can adjust them later, as needed",
                          },
                        ]}
                        symbol="!"
                        className="right alert"
                      />
                    }
                  </div>
                }
                <small>Sticker Price</small>
                <div className="h2 regular">
                  {processCostOfAttendance(breakdowns)}
                </div>
              </div>
              <div className="quote flex flex-col">
                <small>Your Net Cost (Year 1)</small>
                <div className="h2">{processNetCost(1, breakdowns)}</div>
              </div>
            </div>
            <button
              className="remove"
              onClick={() => {
                //If a user is upgraded, remove the school from the user's scenario, else open the premium modal.
                upgraded ||
                  handleAdminToken(
                    getAdminAccessTokenFromCookies(document.cookie)
                  )
                  ? removeSchool()
                  : openPremiumModal();
              }}
            >
              <Cross />
            </button>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default SchoolCard;
