import { DownArrow } from "@icons/index";
import { HelpButton } from "@components/index";

const CAPScoreSelect = ({ register, title, field, label, options, defaultValue, helpInfo }) => {
  var checkbox_id = field + '_checkbox'
  return (
    <label htmlFor={field} className="selectLabel small">
      <div>{title}
      { helpInfo &&
        <HelpButton
          helpContent={[
            {
              id: 0,
              title: title,
              description: helpInfo
            },
          ]}
          className="small right"
        />
      }
      </div>
      <select
        id={field}
        {...register(field)}
        defaultValue = {defaultValue}>
        <option value="">Any Score</option>
        {options.map((option, index) => (
          <option key={index} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      <div className="select-icon md:flex">
        <DownArrow />
      </div>
      <div className="select-checkbox">
        <input 
          id={checkbox_id} 
          {...register(checkbox_id)}
          type="checkbox"
        >
        </input>
        <span>Include schools with no reported range</span>
      </div>
    </label>
  );
};


export default CAPScoreSelect;
